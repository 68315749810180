import * as React from "react";

function SvgBulb(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Bulb_svg__clip0)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M10.17 2.7c.379 0 .686-.307.686-.686v-.915a.687.687 0 00-1.373 0v.915c0 .379.307.686.686.686zM20.009 8.879h-.915a.687.687 0 000 1.373h.915a.687.687 0 000-1.373zM2.389 9.565a.686.686 0 00-.687-.686H.787a.686.686 0 100 1.373h.915c.38 0 .687-.307.687-.687zM16.545 5.017a.686.686 0 00.485-.202l.65-.645a.686.686 0 00-.97-.97l-.65.645a.687.687 0 00.485 1.172zM4.279 3.845l-.65-.645a.686.686 0 00-.97.97l.65.645a.686.686 0 00.97-.97zM17.949 14.827a.687.687 0 00-.97.97l.65.645a.686.686 0 00.97-.97l-.65-.645zM2.389 14.827l-.65.645a.686.686 0 00.97.97l.65-.645a.686.686 0 00-.97-.97z"
          fill="#B9A602"
        />
        <path
          d="M16.805 10.71a6.407 6.407 0 10-9.382 5.665 1.373 1.373 0 00-.22.742v1.373a2.746 2.746 0 002.746 2.745h.915a2.746 2.746 0 002.746-2.745v-1.373c0-.263-.076-.52-.22-.742a6.407 6.407 0 003.415-5.665zm-4.577 6.407v.457H9.94a.687.687 0 000 1.373h2.206c-.194.548-.71.914-1.29.916H9.94a1.373 1.373 0 01-1.373-1.373v-1.373h3.661zm-1.83-1.373a5.034 5.034 0 11.01-10.069 5.034 5.034 0 01-.014 10.069h.004z"
          fill="#3E3C3C"
        />
        <path
          d="M10.398 6.818a.686.686 0 000 1.373 2.522 2.522 0 012.517 2.517.686.686 0 101.373 0 3.895 3.895 0 00-3.89-3.89z"
          fill="#B9A602"
        />
      </g>
      <defs>
        <clipPath id="Bulb_svg__clip0">
          <path fill="#fff" d="M0 0h21.052v21.509H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBulb;
