import * as React from 'react'

function SvgRedHat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 261 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={261} height={111} rx={7} />
      <path
        d="M197.22 63.725c0 2.928 1.756 4.351 4.957 4.351a12.753 12.753 0 002.919-.414v-3.4a6.08 6.08 0 01-1.886.286c-1.318 0-1.807-.414-1.807-1.657v-5.2h3.82v-3.5h-3.82v-4.425l-4.174.906v3.526h-2.772v3.5h2.762l.001 6.027zm-13.013.08c0-.907.906-1.348 2.274-1.348a10.56 10.56 0 012.48.31v1.761a5.266 5.266 0 01-2.61.648c-1.341 0-2.143-.517-2.143-1.372h-.001zm1.278 4.323a6.377 6.377 0 003.771-1.06v.83h4.13v-8.78c0-3.339-2.244-5.17-5.988-5.17a15.21 15.21 0 00-6.384 1.5l1.5 3.082a11.124 11.124 0 014.132-1.088c1.719 0 2.608.672 2.608 2.046v.672a12.13 12.13 0 00-3.1-.389c-3.514 0-5.63 1.477-5.63 4.12 0 2.408 1.91 4.245 4.957 4.245l.004-.008zm-22.704-.223h4.442v-7.1h7.437v7.1h4.454V49.771h-4.437v6.966h-7.437v-6.966h-4.442l-.017 18.134zm-16.907-6.87a3.468 3.468 0 013.454-3.474c.045 0 .091 0 .136.002a4.22 4.22 0 012.887 1.064v4.79a4.007 4.007 0 01-2.887 1.1 3.482 3.482 0 01-3.588-3.369l-.002-.1v-.013zm6.533 6.875h4.132V48.865l-4.172.906v5.154a7.067 7.067 0 10-3.614 13.154 6.153 6.153 0 003.666-1.189l-.012 1.02zm-18.96-10.535a3.023 3.023 0 012.865 2.174h-5.7a2.85 2.85 0 012.831-2.174h.004zm-7.043 3.694a7.182 7.182 0 007.435 7.1 8.188 8.188 0 005.708-2.073l-2.761-2.47a3.69 3.69 0 01-2.735 1.036 3.534 3.534 0 01-3.359-2.174h9.735V61.45c0-4.35-2.917-7.483-6.894-7.483a7.027 7.027 0 00-7.12 6.93v.164l-.009.008zm-7.197-7.49a2.062 2.062 0 012.3 2.045 2.06 2.06 0 01-2.3 2.046h-4.393v-4.092h4.393zm-8.84 14.32h4.442v-6.6h3.381l3.41 6.6h4.967l-3.978-7.252a5.481 5.481 0 003.408-5.1c0-3.263-2.556-5.774-6.384-5.774h-9.246V67.9z"
        fill="#000"
      />
      <path
        d="M86.175 61.435c3.072 0 7.516-.635 7.516-4.3a3.457 3.457 0 00-.076-.842l-1.83-7.967c-.422-1.753-.793-2.549-3.862-4.088C85.541 43.017 80.353 41 78.819 41c-1.429 0-1.844 1.847-3.548 1.847-1.64 0-2.858-1.379-4.392-1.379-1.473 0-2.433 1.007-3.175 3.078 0 0-2.065 5.841-2.33 6.688-.042.156-.06.317-.054.478 0 2.27 8.913 9.714 20.855 9.714v.009zm7.987-2.809c.227.815.37 1.65.425 2.494 0 3.447-3.865 5.361-8.945 5.361-11.481.005-21.542-6.742-21.542-11.202 0-.619.126-1.232.371-1.8-4.126.202-9.471.941-9.471 5.674 0 7.747 18.314 17.305 32.815 17.305 11.118 0 13.921-5.043 13.921-9.025 0-3.132-2.7-6.688-7.57-8.81"
        fill="#E00"
      />
      <path
        d="M94.162 58.623c.227.815.37 1.65.425 2.494 0 3.447-3.865 5.361-8.945 5.361-11.481.007-21.538-6.74-21.538-11.2 0-.619.126-1.231.371-1.8l.9-2.23c-.041.152-.06.31-.054.467 0 2.27 8.913 9.714 20.855 9.714 3.072 0 7.516-.635 7.516-4.3a3.456 3.456 0 00-.076-.842l.546 2.336z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgRedHat
