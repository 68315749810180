import * as React from "react";

function SvgLinkedIn2(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#LinkedIn2_svg__clip0)">
        <path
          d="M27.597.371H2.745A2.745 2.745 0 000 3.116v24.852a2.745 2.745 0 002.745 2.744h24.852a2.745 2.745 0 002.744-2.744V3.116A2.745 2.745 0 0027.597.37zM9.389 26.57a.799.799 0 01-.799.799h-3.4a.799.799 0 01-.799-.8V12.318c0-.44.358-.799.8-.799h3.4c.44 0 .798.358.798.8V26.57zM6.89 10.175a3.23 3.23 0 110-6.46 3.23 3.23 0 010 6.46zm20.267 16.46a.734.734 0 01-.734.733h-3.649a.734.734 0 01-.734-.734V19.95c0-.997.292-4.37-2.606-4.37-2.249 0-2.705 2.308-2.797 3.344v7.711a.734.734 0 01-.734.735h-3.529a.734.734 0 01-.734-.735V12.253c0-.406.329-.735.734-.735h3.53c.405 0 .733.33.733.735v1.243c.834-1.25 2.073-2.217 4.711-2.217 5.843 0 5.81 5.458 5.81 8.457v6.898z"
          fill="#828282"
        />
      </g>
      <defs>
        <clipPath id="LinkedIn2_svg__clip0">
          <path
            fill="#fff"
            transform="translate(0 .371)"
            d="M0 0h30.341v30.341H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLinkedIn2;
