import * as React from "react";

function SvgTie(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#83C6F2" d="M0 0h47.046v48.658H0z" />
      <path
        d="M29.402 36.723l-.001-.01-2.729-23.883-1.402.6a4.055 4.055 0 01-1.6.332c-.541 0-1.083-.11-1.599-.332l-1.402-.6-2.728 23.884-.001.009c-.097.946.2 1.905.813 2.632l2.223 2.633a3.519 3.519 0 002.695 1.252c1.04 0 2.023-.456 2.694-1.252l2.223-2.633c.614-.727.91-1.686.814-2.632zM27.433 4.727H19.91c-.623 0-1.129.505-1.129 1.128v2.08c0 .993.525 1.86 1.337 2.207l2.842 1.216c.459.197.965.197 1.424 0l2.841-1.216c.813-.348 1.337-1.214 1.337-2.207v-2.08c0-.623-.505-1.128-1.128-1.128z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTie;
