import * as React from 'react'

function SvgAppLogo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 132 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.816 11.717l5.858 2.93 5.858-2.93V0H12.816v11.717zM0 22.063L6.21 32l9.936-6.21v-6.908l-6.21-3.028L0 22.064zm37.559 0L31.349 32l-9.936-6.21v-6.908l6.209-3.028 9.937 6.21z"
        fill="#83C6F2"
      />
      <path
        d="M73.495 5.105h-3.968L63.51 15.153 57.527 5.105h-3.968l8.064 13.44v8.96h3.776v-8.96l8.096-13.44zM87.416 4.049v7.776c-1.536-1.28-3.488-2.016-5.696-2.016-5.248 0-8.992 3.872-8.992 9.088 0 5.152 3.744 9.056 8.992 9.056 2.4 0 4.544-.896 6.08-2.4l.48 1.984h2.688V4.049h-3.552zm-5.44 20.512c-3.232 0-5.632-2.432-5.632-5.696 0-3.232 2.4-5.664 5.632-5.664 3.264 0 5.664 2.432 5.664 5.664 0 3.232-2.4 5.696-5.664 5.696zM112.455 18.929c0-5.024-3.52-9.12-8.832-9.12-5.056 0-8.864 3.904-8.864 9.056 0 5.184 3.808 9.088 8.864 9.088 3.872 0 7.04-2.24 8.416-5.888h-3.744c-.928 1.6-2.592 2.56-4.672 2.56-2.816 0-4.832-1.728-5.28-4.544h14.016c.064-.384.096-.768.096-1.152zm-8.832-5.888c2.656 0 4.608 1.536 5.248 4.16H98.439c.576-2.56 2.528-4.16 5.184-4.16zM127.534 10.225l-5.056 12.832-5.184-12.832h-3.744l7.296 17.28h3.232l7.104-17.28h-3.648z"
        fill="#051A26"
      />
    </svg>
  )
}

export default SvgAppLogo
