import * as React from "react";

function SvgMoneyBag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 61 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.506 6.078H6.777V2.35a.35.35 0 10-.699 0v3.728H2.35a.35.35 0 000 .7h3.728v3.728a.35.35 0 10.7 0V6.777h3.728a.35.35 0 100-.699zM59.104 14.934h-3.729v-3.729a.35.35 0 00-.7 0v3.729h-3.728a.35.35 0 000 .699h3.729v3.729a.35.35 0 00.699 0v-3.73h3.729a.35.35 0 100-.698z"
        fill="#000"
        stroke="#000"
        strokeWidth={2.282}
      />
      <path
        d="M35.199 36.59a2.183 2.183 0 00-.612-.444c-.488-.255-1.022-.409-1.552-.548v4.105c.86-.097 1.847-.41 2.299-1.212.215-.383.258-.855.17-1.282a1.289 1.289 0 00-.305-.618zM35.332 38.5l.005-.009-.005.01zM29 30.028a1.54 1.54 0 00-.266.722c-.026.26-.012.551.088.797.094.235.292.408.497.547.229.156.481.278.738.381.215.087.458.17.719.25V29.01c-.66.14-1.381.441-1.776 1.018zM35.353 38.46l-.017.031.017-.03zM35.375 38.423c.002-.003.002-.003 0 0z"
        fill="#000"
      />
      <path
        d="M34.482 16.09c3.491-2.933 5.824-9.54 4.388-9.83-1.907-.384-6.05 1.3-8.052 1.597-2.84.343-5.933-3.08-7.669-1.172-1.412 1.552 1.012 7.192 4.804 9.65-11.313 5.56-27.214 33.471.526 35.497 38.383 2.802 19.163-30.324 6.003-35.742zm3.296 22.012a4.035 4.035 0 01-1.557 2.74c-.91.706-2.051 1.034-3.186 1.126v1.202c0 .322-.14.633-.381.846a1.138 1.138 0 01-1.22.18 1.139 1.139 0 01-.657-1.026v-1.314a7.418 7.418 0 01-.578-.133c-1.06-.291-2.043-.859-2.762-1.699a4.978 4.978 0 01-.992-1.815c-.037-.125-.075-.252-.089-.382a1.13 1.13 0 011.224-1.238c.443.04.832.347.971.77.043.13.072.263.12.391s.106.252.174.37c.134.234.302.449.496.635.399.385.907.634 1.436.783v-4.467c-1.035-.268-2.105-.614-2.96-1.28a3.445 3.445 0 01-1-1.2c-.243-.502-.344-1.06-.348-1.616a3.851 3.851 0 01.338-1.637c.22-.48.538-.913.927-1.27.831-.766 1.936-1.193 3.044-1.35V25.47c0-.321.14-.633.38-.845a1.139 1.139 0 011.22-.18c.397.182.657.589.657 1.025v1.242c.148.018.295.04.442.067 1.088.197 2.152.646 2.947 1.43a4.309 4.309 0 011.062 1.71c.043.131.086.266.106.403a1.13 1.13 0 01-1.166 1.294 1.14 1.14 0 01-1.004-.723c-.048-.124-.071-.254-.12-.377a1.988 1.988 0 00-.184-.356 2.154 2.154 0 00-.533-.559c-.453-.334-1.002-.508-1.55-.605v4.274c.652.156 1.304.326 1.929.573.943.372 1.838.942 2.353 1.842-.08-.14-.158-.277.002.005.158.276.083.145.004.006.41.722.544 1.586.455 2.406zM35.318 38.52l-.017.03.017-.03z"
        fill="#000"
      />
      <circle cx={6.591} cy={18.361} r={1.458} fill="#000" />
    </svg>
  );
}

export default SvgMoneyBag;
