import * as React from "react";

function SvgStarChecked(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 173 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M109.649 0l-2.987 1.53-20.184 10.353L63.3.015 49.17 21.888 23.164 23.2l-1.298 26.013L0 63.351l11.883 23.17L.015 109.7l21.873 14.13 1.312 26.006 26.013 1.298L63.351 173l23.17-11.883 23.179 11.869 14.13-21.874 26.006-1.312 1.298-26.013L173 109.649l-11.883-23.17 11.869-23.18-21.874-14.13-1.312-26.005-26.013-1.298L109.649 0zm-2.588 9.672l12.557 19.422 23.106 1.146 1.167 23.106 19.423 12.55-10.534 20.582 10.548 20.583-19.422 12.557-1.146 23.106-23.106 1.167-12.549 19.423-20.583-10.534-20.583 10.548-12.557-19.422-23.106-1.146-1.167-23.106-19.423-12.549L20.22 86.522 9.672 65.939l19.422-12.557 1.146-23.106 23.106-1.167 12.55-19.423L86.477 20.22l20.583-10.548zm7.185 52.57L95.312 80.594 76.38 98.948 58.812 81.382l-7.873 7.873 25.317 25.317 45.74-44.334-7.75-7.997z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgStarChecked;
