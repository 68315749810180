import * as React from "react";

function SvgFooterAppLogo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 169 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.42 15.012l7.506 3.754 7.506-3.754V0H16.42v15.012zM0 28.27l7.955 12.73 12.732-7.955v-8.851l-7.956-3.88L0 28.27zm48.122 0l-7.955 12.73-12.732-7.955v-8.851l7.956-3.88 12.731 7.956z"
        fill="#83C6F2"
      />
      <path
        d="M94.165 6.54h-5.084l-7.708 12.873L73.706 6.54h-5.084l10.332 17.22v11.48h4.838V23.76L94.165 6.54zM112.001 5.187v9.962c-1.968-1.64-4.469-2.582-7.298-2.582-6.724 0-11.52 4.96-11.52 11.643 0 6.601 4.796 11.603 11.52 11.603 3.075 0 5.822-1.148 7.79-3.075l.615 2.543h3.444V5.187h-4.551zm-6.97 26.28c-4.141 0-7.216-3.115-7.216-7.297 0-4.142 3.075-7.258 7.216-7.258 4.182 0 7.257 3.117 7.257 7.258 0 4.14-3.075 7.297-7.257 7.297zM144.083 24.252c0-6.438-4.51-11.685-11.316-11.685-6.478 0-11.357 5.002-11.357 11.602 0 6.642 4.879 11.644 11.357 11.644 4.961 0 9.02-2.87 10.783-7.544h-4.797c-1.189 2.05-3.321 3.28-5.986 3.28-3.608 0-6.191-2.213-6.765-5.822h17.958c.082-.492.123-.983.123-1.476zm-11.316-7.544c3.403 0 5.904 1.968 6.724 5.33h-13.366c.738-3.28 3.239-5.33 6.642-5.33zM163.403 13.1l-6.478 16.44-6.641-16.44h-4.797l9.348 22.14h4.14l9.102-22.14h-4.674z"
        fill="#9DA2A6"
      />
    </svg>
  );
}

export default SvgFooterAppLogo;
