import schoolOfDesign from './schoolOfDesign'
import cloudEngSchool from './cloudEngineering'
import dataSchool from './dataSchool'
import devOpsSchool from './devOpsSchool'
import productSchool from './productSchool'
import qaSchool from './qaTesting'
import salesForceSchool from './saleforceSchool'
import softwareSchool from './softwareSchool'

const SchoolsLists = {
  designSchool: schoolOfDesign,
  softwareSchool,
  dataSchool,
  productSchool,
  devOpsSchool,
  qaSchool,
  cloudEngSchool,
  salesForceSchool,
}

export default SchoolsLists
