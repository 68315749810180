import Styled from 'styled-components'

export default Styled.div`
    div.modal--container {
        width: 35rem;
    }
    form {
        padding: 1em 2em;
        padding-bottom: 3em;
    }
    header.component--header {
        svg {
            font-size: 6rem;
        }
    }
`
