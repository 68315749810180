import * as React from "react";

function SvgEye(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#eye_svg__clip0)">
        <path
          d="M52.313 24.642c-.465-.55-11.59-13.425-25.813-13.425-14.222 0-25.347 12.876-25.813 13.425a2.856 2.856 0 000 3.716c.466.549 11.59 13.424 25.813 13.424 14.222 0 25.348-12.876 25.814-13.425a2.855 2.855 0 000-3.715zm-21.469-5.69c.957-.523 2.29.076 2.979 1.337.688 1.26.47 2.706-.486 3.228-.955.523-2.29-.076-2.978-1.337-.688-1.26-.471-2.706.485-3.229zM26.5 37.117c-9.934 0-18.295-7.63-21.189-10.617 1.954-2.019 6.403-6.151 12.145-8.623a10.762 10.762 0 00-1.776 5.932c0 5.976 4.844 10.82 10.82 10.82s10.82-4.844 10.82-10.82c0-2.192-.656-4.228-1.776-5.932 5.742 2.472 10.19 6.604 12.145 8.623-2.894 2.99-11.254 10.617-21.189 10.617z"
          fill="#83C6F2"
        />
      </g>
      <defs>
        <clipPath id="eye_svg__clip0">
          <path fill="#fff" d="M0 0h53v53H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgEye;
