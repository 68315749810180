import * as React from "react";

function SvgLoveCode(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={53} cy={53} r={53} fill="#FFAF30" />
      <path
        d="M59.44 38.718a7.468 7.468 0 00-6.618 4.042c-1.79-3.584-6.15-5.033-9.809-3.279-2.491 1.22-4.126 3.66-4.126 6.481v.534c.155 1.83 1.012 3.584 2.413 4.804l10.9 10.675c.155.152.389.228.622.228.234 0 .468-.076.623-.228l10.9-10.675.544-.534a7.367 7.367 0 001.869-4.27v-.534c.078-3.965-3.27-7.244-7.318-7.244zm-26.937 4.118c-.156-.534-.779-.915-1.324-.839-.233 0-.467.077-.7.229l-10.277 7.168c-.7.457-.7 1.6 0 2.058l10.355 7.244c.545.381 1.401.229 1.79-.305.39-.534.234-1.372-.311-1.754l-8.875-6.176 8.875-6.176c.467-.305.7-.915.467-1.449zm53.095 6.558l-10.432-7.168a1.274 1.274 0 00-.7-.229c-.546 0-1.09.305-1.324.839-.156.534 0 1.144.467 1.449l8.875 6.176-8.875 6.176c-.545.382-.7 1.22-.311 1.754.389.534 1.245.686 1.79.305l10.355-7.167c.778-.458.778-1.602.155-2.136z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLoveCode;
