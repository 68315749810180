import * as React from "react";

function SvgCapman(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.121 20.267c1.126 0 2.038-.987 2.038-2.205s-.912-2.205-2.037-2.205c-1.126 0-2.038.988-2.038 2.205 0 1.218.912 2.205 2.037 2.205z"
        fill="#FFCEBF"
      />
      <path
        d="M17.344 24.005H12.9V22.67c0-1.328.995-2.404 2.222-2.404 1.228 0 2.222 1.076 2.222 2.404v1.334z"
        fill="#3C87D0"
      />
      <path
        d="M19.567 20.267c1.125 0 2.037-.987 2.037-2.205s-.912-2.205-2.037-2.205-2.038.988-2.038 2.205c0 1.218.913 2.205 2.038 2.205z"
        fill="#FFCEBF"
      />
      <path
        d="M21.788 24.005h-4.444V22.67c0-1.328.995-2.404 2.222-2.404 1.227 0 2.222 1.076 2.222 2.404v1.334z"
        fill="#3C87D0"
      />
      <path
        d="M14.203 7.338h1.839c.12-.386.318-.737.581-1.03.386-.429.616-1.023.59-1.676-.045-1.17-.927-2.12-2.008-2.164-1.194-.05-2.176.982-2.176 2.263 0 .614.226 1.17.593 1.578.262.292.46.643.58 1.03z"
        fill="#FDB97E"
      />
      <path
        d="M14.203 7.339h1.84v.815c0 .55-.412.995-.92.995s-.92-.446-.92-.995v-.815z"
        fill="#537983"
      />
      <path
        d="M15.122 1.74c-.182 0-.329-.16-.329-.356V.557c0-.197.147-.356.329-.356.181 0 .329.16.329.356v.827c0 .197-.148.356-.33.356zM13.328 2.29a.315.315 0 01-.232-.104l-.54-.585a.377.377 0 010-.503.312.312 0 01.464 0l.54.585a.377.377 0 010 .503.316.316 0 01-.232.105zM16.917 2.29a.316.316 0 01-.233-.104.377.377 0 010-.503l.54-.585a.312.312 0 01.466 0 .377.377 0 010 .503l-.54.585a.316.316 0 01-.233.105zM18.896 3.925h-.765c-.181 0-.328-.159-.328-.355 0-.197.147-.356.328-.356h.765c.182 0 .329.16.329.356 0 .196-.147.355-.329.355zM12.113 3.925h-.765c-.181 0-.329-.159-.329-.355 0-.197.148-.356.33-.356h.764c.181 0 .329.16.329.356 0 .196-.148.355-.33.355z"
        fill="#FEA95C"
      />
      <path
        d="M18.32 13.178h-.361V10.8c0-.367-.275-.664-.614-.664H12.78c-.34 0-.614.297-.614.664 0 .367.275.664.614.664h3.951v1.714h-.361c-.138 0-.22.166-.144.29l.975 1.583c.068.111.22.111.288 0l.975-1.582c.076-.125-.006-.291-.144-.291z"
        fill="#3C87D0"
      />
      <path
        d="M1.909 15.313C.855 15.313 0 16.238 0 17.38v3.541h11.449V17.38c0-1.14-.855-2.066-1.909-2.066H1.91z"
        fill="#C4E2FF"
      />
      <path
        d="M9.54 15.313H8.05c1.054 0 1.909.925 1.909 2.066v3.541h1.49V17.38c0-1.14-.854-2.066-1.908-2.066z"
        fill="#B3DAFE"
      />
      <path
        d="M3.957 11.336v3.102l1.762 1.27 1.762-1.27v-3.102H3.957z"
        fill="#FFBFB1"
      />
      <path
        d="M9.97 6.828c-.02 0-.04 0-.059.002-.336-2.177-2.085-3.837-4.193-3.837-2.108 0-3.857 1.66-4.194 3.837l-.059-.002c-.797 0-1.444.7-1.444 1.562 0 .863.647 1.563 1.444 1.563.02 0 .04 0 .06-.002.336 2.177 2.085 3.837 4.193 3.837 2.108 0 3.857-1.66 4.193-3.837l.06.002c.797 0 1.443-.7 1.443-1.563 0-.862-.646-1.562-1.444-1.562z"
        fill="#FFCEBF"
      />
      <path
        d="M9.97 6.828c-.02 0-.04 0-.059.002-.336-2.177-2.085-3.837-4.193-3.837-2.108 0-3.857 1.66-4.194 3.837l-.059-.002c-.797 0-1.444.7-1.444 1.562 0 .863.647 1.563 1.444 1.563.02 0 .04 0 .06-.002.336 2.177 2.085 3.837 4.193 3.837 2.108 0 3.857-1.66 4.193-3.837l.06.002c.797 0 1.443-.7 1.443-1.563 0-.862-.646-1.562-1.444-1.562z"
        fill="#FFCEBF"
      />
      <path
        d="M9.971 6.828c-.02 0-.039 0-.058.002-.337-2.177-2.086-3.837-4.194-3.837-.211 0-.418.018-.621.05C6.92 3.332 8.368 4.87 8.67 6.83l.059-.002c.797 0 1.444.7 1.444 1.562 0 .863-.647 1.563-1.444 1.563-.02 0-.04 0-.06-.002-.302 1.96-1.749 3.498-3.571 3.787.203.033.41.05.621.05 2.108 0 3.857-1.66 4.194-3.837l.058.002c.798 0 1.444-.7 1.444-1.562 0-.863-.646-1.563-1.444-1.563z"
        fill="#FFBFB1"
      />
      <path
        d="M3.643 9.504c-.181 0-.329-.16-.329-.356v-.847c0-.196.148-.356.33-.356.18 0 .328.16.328.356v.847c0 .197-.147.356-.329.356zM7.796 9.504c-.182 0-.33-.16-.33-.356v-.847c0-.196.148-.356.33-.356.181 0 .328.16.328.356v.847c0 .197-.147.356-.328.356z"
        fill="#384949"
      />
      <path
        d="M5.718 11.92c-.68 0-1.232-.598-1.232-1.333 0-.196.148-.356.33-.356.18 0 .328.16.328.356 0 .343.258.622.574.622.317 0 .575-.28.575-.622 0-.196.147-.356.328-.356.182 0 .33.16.33.356 0 .735-.553 1.333-1.233 1.333z"
        fill="#FFB09E"
      />
      <path
        d="M8.63 1.475C8.312.924 7.745.557 7.1.557H3.642C1.88.557.452 2.102.452 4.009V6.83h6.04c.617 0 1.18-.252 1.607-.665.298.405.758.665 1.274.665h1.61V4.058c0-1.414-1.05-2.563-2.354-2.583z"
        fill="#C4573A"
      />
      <path
        d="M5.718 15.709l-.904 1.02.328 1.388h1.151l.328-1.388-.903-1.02zM5.142 18.117l-.51 2.804h2.17l-.509-2.804H5.142z"
        fill="#FD544D"
      />
      <path
        d="M3.956 14.438l-1.49.875 2.102 1.694 1.15-1.299-1.762-1.27zM7.48 14.438l1.49.875-2.101 1.694-1.15-1.299 1.762-1.27z"
        fill="#B3DAFE"
      />
      <path
        d="M8.697 10.557h-.545c-.198 0-.359-.174-.359-.389 0-.215.16-.389.36-.389h.544c.199 0 .36.174.36.39 0 .214-.161.388-.36.388zM3.283 10.557h-.545c-.198 0-.36-.174-.36-.389 0-.215.162-.389.36-.389h.545c.199 0 .36.174.36.39 0 .214-.161.388-.36.388z"
        fill="#FFB09E"
      />
      <path
        d="M7.48 14.438l1.49.875-2.101 1.694-1.15-1.299 1.762-1.27z"
        fill="#B3DAFE"
      />
      <path
        d="M8.631 1.475C8.313.924 7.748.557 7.103.557H5.61c.645 0 1.21.367 1.529.918 1.303.02 2.354 1.169 2.354 2.583V6.83h1.49V4.058c0-1.414-1.05-2.563-2.353-2.583z"
        fill="#A74B30"
      />
      <path
        d="M7.48 14.438l-.67.482.667.393-1.23.993.62.701 2.101-1.694-1.489-.876z"
        fill="#8AC9FE"
      />
      <path
        d="M17.213 4.632c-.046-1.17-.928-2.12-2.01-2.164a1.952 1.952 0 00-.518.048c.915.21 1.617 1.076 1.658 2.116a2.362 2.362 0 01-.59 1.677 2.766 2.766 0 00-.582 1.03h.87c.12-.387.319-.738.581-1.03.386-.43.616-1.024.59-1.677z"
        fill="#FEA95C"
      />
      <path
        d="M15.172 7.339v.815c0 .38-.196.709-.485.877.13.075.278.118.435.118.508 0 .92-.446.92-.995v-.815h-.87z"
        fill="#3E5959"
      />
      <path
        d="M15.121 15.857c-.216 0-.425.037-.621.105.821.285 1.416 1.117 1.416 2.1 0 .983-.595 1.816-1.416 2.1.196.068.405.105.621.105 1.126 0 2.038-.987 2.038-2.205s-.912-2.205-2.038-2.205zM19.567 15.857c-.217 0-.426.037-.622.105.822.285 1.416 1.117 1.416 2.1 0 .983-.594 1.816-1.416 2.1.196.068.405.105.622.105 1.125 0 2.037-.987 2.037-2.205s-.912-2.205-2.037-2.205z"
        fill="#FFBFB1"
      />
      <path
        d="M15.121 20.267c-.215 0-.424.034-.621.096.925.29 1.6 1.213 1.6 2.308v1.334h1.243V22.67c0-1.328-.994-2.404-2.222-2.404zM19.565 20.267c-.216 0-.424.034-.622.096.925.29 1.601 1.213 1.601 2.308v1.334h1.243V22.67c0-1.328-.995-2.404-2.222-2.404z"
        fill="#0078C8"
      />
    </svg>
  );
}

export default SvgCapman;
