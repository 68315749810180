import * as React from 'react'

function SvgGoogle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 261 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={261} height={111} rx={7} />
      <g clipPath="url(#Google_svg__clip0)">
        <path
          d="M85.664 36.227h1.059c3.676.08 7.292 1.566 9.89 4.197-.96.984-1.939 1.928-2.878 2.912-1.458-1.325-3.216-2.35-5.134-2.711-2.837-.603-5.894-.06-8.271 1.566-2.597 1.708-4.355 4.6-4.735 7.692-.42 3.053.44 6.267 2.417 8.636 1.898 2.31 4.795 3.756 7.792 3.857 2.797.16 5.714-.703 7.751-2.671 1.599-1.386 2.338-3.495 2.578-5.544-3.317 0-6.633.02-9.95 0v-4.137h13.965c.719 4.439-.32 9.44-3.676 12.613-2.237 2.249-5.334 3.575-8.49 3.836-3.057.301-6.214-.282-8.93-1.768-3.257-1.747-5.854-4.7-7.133-8.174-1.199-3.193-1.219-6.808-.1-10.022 1.02-2.932 2.977-5.523 5.494-7.33 2.418-1.788 5.354-2.772 8.351-2.952z"
          fill="#4285F4"
        />
        <path
          d="M165.777 37.271h4.274v28.68c-1.417 0-2.856.02-4.274-.02.019-9.54 0-19.1 0-28.66z"
          fill="#0F9D58"
        />
        <path
          d="M109.658 47.212c2.637-.502 5.494.06 7.672 1.647 1.977 1.406 3.356 3.615 3.776 6.025.539 2.792-.14 5.845-1.938 8.054-1.938 2.47-5.115 3.796-8.211 3.595-2.837-.16-5.594-1.587-7.273-3.916-1.897-2.571-2.357-6.106-1.318-9.118 1.039-3.234 3.976-5.704 7.292-6.287zm.599 3.816a5.374 5.374 0 00-2.797 1.788c-1.937 2.33-1.818 6.125.32 8.294 1.219 1.245 3.057 1.828 4.755 1.486 1.578-.28 2.957-1.345 3.716-2.751 1.318-2.39.939-5.684-1.079-7.551-1.299-1.205-3.197-1.708-4.915-1.266z"
          fill="#DB4437"
        />
        <path
          d="M130.835 47.212c3.016-.582 6.313.261 8.57 2.39 3.676 3.314 4.076 9.52.939 13.336-1.898 2.41-4.974 3.736-8.011 3.595-2.897-.08-5.754-1.526-7.472-3.916-1.938-2.631-2.357-6.246-1.258-9.32 1.099-3.132 3.975-5.522 7.232-6.085zm.599 3.816a5.457 5.457 0 00-2.797 1.768c-1.918 2.29-1.838 6.025.22 8.214 1.219 1.305 3.117 1.948 4.875 1.587 1.558-.302 2.956-1.346 3.716-2.752 1.298-2.41.919-5.704-1.119-7.572-1.299-1.205-3.197-1.687-4.895-1.245z"
          fill="#F4B400"
        />
        <path
          d="M148.795 48.377c2.297-1.446 5.354-1.848 7.831-.602.78.341 1.419.924 2.038 1.506.02-.542 0-1.105.02-1.667 1.339.02 2.677 0 4.036.02v17.714c-.02 2.671-.699 5.503-2.617 7.451-2.098 2.149-5.315 2.812-8.211 2.37-3.097-.462-5.794-2.731-6.993-5.603 1.199-.583 2.458-1.045 3.696-1.587.699 1.647 2.118 3.053 3.896 3.374 1.778.321 3.836-.12 4.994-1.607 1.239-1.526 1.239-3.615 1.179-5.483-.919.904-1.978 1.707-3.256 2.009-2.777.783-5.834-.181-7.972-2.069-2.157-1.888-3.436-4.8-3.316-7.692.06-3.274 1.898-6.427 4.675-8.134zm4.135 2.57c-1.218.202-2.357.884-3.136 1.828-1.878 2.25-1.878 5.845.02 8.054 1.079 1.306 2.817 2.029 4.495 1.848 1.578-.16 3.037-1.165 3.816-2.55 1.318-2.35 1.099-5.544-.679-7.593a4.903 4.903 0 00-4.516-1.586z"
          fill="#4285F4"
        />
        <path
          d="M175.186 49.401c2.398-2.249 6.093-3.012 9.171-1.827 2.916 1.104 4.775 3.896 5.713 6.768-4.335 1.808-8.65 3.595-12.986 5.403.6 1.144 1.519 2.189 2.757 2.61 1.739.623 3.816.402 5.275-.763.579-.442 1.039-1.024 1.478-1.586 1.098.743 2.198 1.466 3.297 2.209-1.559 2.35-4.176 3.996-6.993 4.258-3.116.381-6.432-.824-8.451-3.274-3.316-3.856-2.996-10.323.739-13.797zm2.138 3.716c-.679.984-.959 2.189-.938 3.374 2.896-1.205 5.793-2.41 8.69-3.635-.479-1.125-1.638-1.808-2.817-1.989-1.898-.34-3.876.683-4.935 2.25z"
          fill="#DB4437"
        />
      </g>
      <defs>
        <clipPath id="Google_svg__clip0">
          <path
            fill="#fff"
            transform="translate(71 36)"
            d="M0 0h119.07v40H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgGoogle
