import * as React from "react";

function SvgTwitter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.606 5.853a2.845 2.845 0 00-2.79 3.404.922.922 0 01-1.016 1.095 10.293 10.293 0 01-5.377-2.345c.028.555.22 1.145.638 1.763l.626.925-1.028.438-.198.085c.148.168.325.34.523.511.265.227.545.435.839.623l.01.007 1.427.865-1.493.747c-.073.037-.147.07-.22.1a4.92 4.92 0 001.052.668l1.28.606-1.072.925c-.585.505-1.174.882-1.938 1.081 1.172.625 2.48.95 3.807.947 4.43 0 7.997-3.535 7.997-7.866v-.479l.392-.276c.611-.43 1.044-1.01 1.354-1.674h-1.991l-.251-.526a2.844 2.844 0 00-2.57-1.624zM4.21 12.582a.921.921 0 00-.219.734c.104.739.546 1.374 1.034 1.86.133.132.275.258.424.379l-.069.018c-.443.111-1.041.134-2.038.037a.922.922 0 00-.75 1.562 9.878 9.878 0 007.083 2.97c5.266 0 9.585-4.09 9.83-9.249 1.188-.998 1.783-2.35 2.103-3.579a.92.92 0 00-.892-1.155h-2.169a4.688 4.688 0 00-8.613 2.156 8.493 8.493 0 01-4.351-2.742.922.922 0 00-1.542.212c-.559 1.219-.666 2.623-.042 4.052l-.216.092a.922.922 0 00-.526 1.1c.172.6.557 1.131.952 1.553z"
        fill="#CECECE"
      />
    </svg>
  );
}

export default SvgTwitter;
