import Styled from 'styled-components'

export default Styled.div`
        position: relative;
        svg {
            position: absolute;
            top: -0.3em;
            font-size: 1.5rem;
            left: -0.8em;
            z-index: 1;
        }
        * {
            position: relative;
            z-index: 2;
        }
        

    

`
