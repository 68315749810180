const routes = {
  home: '/home',
  faq: '/faq',
  termsandagreement: '/terms&agreement',
  privacypolicy: '/privacyPolicy',
  hireAlumni: '/hireOurAlumni',
  curriculum: '/curriculum',
  aboutUs: {
    initial: '/aboutUs',
    studentStories: '/aboutUs/studentStories',
    ourStory: '/aboutUs/ourStory',
    events: '/aboutUs/events',
    faq: '/aboutUs/faq',
  },
  enterprise: {
    corportateTraining: '/enterprise/corportateTraining',
    hireOurAlumni: '/enterprise/hireOurAlumni',
    hiredEd: '/enterprise/hiredEd',
  },
  career: {
    instructor: '/career/instructor',
    mentor: '/career/mentor',
  },
  programs: {
    initial: '/programs',
    designSchool: '/programs/designSchool',
    softwareSchool: '/programs/softwareSchool',
    dataSchool: '/programs/dataSchool',
    productSchool: '/programs/productSchool',
    devOpsSchool: '/programs/devOpsSchool',
    qaSchool: '/programs/qaSchool',
    cloudSchool: '/programs/cloudEngSchool',
    salesForceSchool: '/programs/salesForceSchool',
  },
  corporateTraining: '/corporateTraining',
  contactUs: '/contactUs',
  studentStories: '/studentStories',
  resources: '/resources',
  blog: '/blog',
  hireGraduates: '/hireOurGraduates',
  jobDetails: '/jobDetails',
  // aboutUs
}

export default routes
