import * as React from "react";

function SvgGraduationCap(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 81 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M79.11 16.363L40.928 1.091a1.276 1.276 0 00-.945 0L1.8 16.363a1.273 1.273 0 000 2.364l13.2 5.28v26.63a1.271 1.271 0 00.78 1.172C23.958 55.253 32.261 57 40.454 57c8.193 0 16.496-1.747 24.675-5.191a1.272 1.272 0 00.78-1.173V24.008l6.363-2.546v10.244a5.091 5.091 0 102.546 0V20.444l4.29-1.716a1.274 1.274 0 000-2.365zM63.364 49.785c-7.612 3.1-15.315 4.67-22.909 4.67-7.593 0-15.296-1.57-22.909-4.67v-24.76L39.983 34c.303.121.641.121.945 0l22.436-8.974v24.76zm12.728-13.149a2.545 2.545 0 11-5.091 0 2.545 2.545 0 015.09 0zm-35.637-5.19L5.7 17.547 40.455 3.644 75.21 17.546l-34.755 13.9z"
        fill="#83C6F2"
        stroke="#83C6F2"
        strokeWidth={1.5}
      />
    </svg>
  );
}

export default SvgGraduationCap;
