import * as React from "react";

function SvgTime(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 0C11.64 0 0 11.64 0 26s11.64 26 26 26 26-11.64 26-26C51.984 11.647 40.353.016 26 0zm0 50.267C12.598 50.267 1.733 39.402 1.733 26S12.598 1.733 26 1.733 50.267 12.598 50.267 26C50.25 39.396 39.396 50.251 26 50.267z"
        fill="#83C6F2"
      />
      <path
        d="M26 4.334c-11.966 0-21.667 9.7-21.667 21.667 0 11.966 9.7 21.666 21.667 21.666 11.966 0 21.666-9.7 21.666-21.666C47.653 14.04 37.96 4.347 26 4.334zm0 41.6c-11.01 0-19.934-8.924-19.934-19.933C6.066 14.99 14.991 6.067 26 6.067c11.008 0 19.933 8.925 19.933 19.934C45.92 37.004 37.003 45.92 26 45.934z"
        fill="#83C6F2"
      />
      <path
        d="M26.866 8.666h-1.733v1.733h1.733V8.666zM26.866 41.6h-1.733v1.733h1.733V41.6zM18.082 10.557l-1.5.867.866 1.5 1.5-.866-.866-1.5zM34.544 39.073l-1.5.867.866 1.5 1.5-.867-.866-1.5zM11.421 16.583l-.866 1.501 1.5.867.867-1.501-1.5-.867zM39.94 33.054l-.867 1.5 1.501.867.867-1.5-1.501-.867zM10.4 25.133H8.667v1.733H10.4v-1.733zM43.334 25.133H41.6v1.733h1.733v-1.733zM12.056 33.051l-1.501.867.866 1.501 1.501-.866-.866-1.502zM40.574 16.583l-1.5.866.866 1.501 1.501-.866-.867-1.501zM17.444 39.076l-.867 1.5 1.5.867.867-1.5-1.5-.867zM33.915 10.559l-.866 1.5 1.5.866.866-1.5-1.5-.867zM25.133 12.133v13.376l-8.246 4.948.892 1.485 8.667-5.2a.866.866 0 00.42-.742V12.133h-1.733z"
        fill="#83C6F2"
      />
    </svg>
  );
}

export default SvgTime;
