import * as React from "react";

function SvgPayment(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.682 25.714v-7.168c0-2.43-1.886-4.407-4.268-4.599L37.758 2.32A4.58 4.58 0 0034.953.157a4.555 4.555 0 00-3.489.467L8.644 13.91H4.636A4.641 4.641 0 000 18.546v27.818a4.641 4.641 0 004.636 4.637h39.41a4.641 4.641 0 004.636-4.637v-7.168A3.472 3.472 0 0051 35.933v-6.955c0-1.51-.972-2.784-2.318-3.264zM41.72 13.91H31.618l7.577-4.412 2.526 4.412zm-3.677-6.423L27.01 13.91h-4.576l14.464-8.422 1.145 1.999zm-5.413-4.86a2.242 2.242 0 011.722-.231 2.273 2.273 0 011.392 1.076l.003.005-17.92 10.433h-4.576l19.38-11.282zm13.733 43.737a2.321 2.321 0 01-2.319 2.319H4.636a2.321 2.321 0 01-2.318-2.319V18.546a2.321 2.321 0 012.318-2.318h39.41a2.321 2.321 0 012.318 2.318v6.955h-6.955a6.962 6.962 0 00-6.954 6.954 6.962 6.962 0 006.954 6.955h6.955v6.954zm2.318-10.431c0 .639-.52 1.159-1.16 1.159H39.41a4.641 4.641 0 01-4.636-4.637 4.641 4.641 0 014.636-4.636h8.114c.64 0 1.159.52 1.159 1.159v6.955z"
        fill="#83C6F2"
      />
      <path
        d="M39.409 30.137a2.321 2.321 0 00-2.318 2.318 2.321 2.321 0 002.318 2.318 2.321 2.321 0 002.318-2.318 2.321 2.321 0 00-2.318-2.318z"
        fill="#83C6F2"
      />
    </svg>
  );
}

export default SvgPayment;
