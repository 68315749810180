import * as React from 'react'

function SvgIbm(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 261 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={261} height={111} rx={7} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81 36v2.737h19.465V36H81zm22.223 0v2.737h27.738S128.128 36 124.375 36h-21.152zm33.179 0v2.737h16.782L152.188 36h-15.786zm28.813 0l-.996 2.737h16.629V36h-15.633zM81 41.322v2.737h19.465v-2.737H81zm22.223.004v2.733h30.957s-.362-2.106-.993-2.733h-29.964zm33.179 0v2.733h18.621l-.922-2.733h-17.699zm26.821 0l-.922 2.733h18.547v-2.733h-17.625zm-76.63 5.318v2.741h8.43v-2.74h-8.43zm22.223 0v2.741h8.43v-2.74h-8.43zm16.629 0v2.741h8.43s.535-1.447.535-2.74h-8.965zm16.551 0v2.741h14.945l-.996-2.74h-13.949zm19.391 0l-1 2.741h15.019v-2.74h-14.019zm-74.793 5.327v2.736h8.43v-2.736h-8.43zm22.222 0v2.736h21.532s1.801-1.404 2.375-2.736h-23.907zm33.18 0v2.736h8.43v-1.523l.535 1.523h15.441l.575-1.523v1.523h8.429v-2.736h-15.824l-.84 2.318-.844-2.318h-15.902zm-55.402 5.322v2.736h8.43v-2.736h-8.43zm22.222 0v2.736h23.907c-.574-1.328-2.375-2.736-2.375-2.736h-21.532zm33.18 0v2.736h8.43v-2.736h-8.43zm9.961 0l1.019 2.736h11.579l.968-2.736h-13.566zm15.019 0v2.736h8.43v-2.736h-8.43zm-80.382 5.322v2.737h8.43v-2.737h-8.43zm22.222 0v2.737h8.43v-2.737h-8.43zm16.629 0v2.737h8.965c0-1.292-.535-2.737-.535-2.737h-8.43zm16.551 0v2.737h8.43v-2.737h-8.43zm11.875 0l.988 2.737h7.762l.996-2.737h-9.746zm13.105 0v2.737h8.43v-2.737h-8.43zm-85.824 5.322v2.74h19.465v-2.74H81.152zm22.071 0v2.74h29.964c.631-.627.993-2.74.993-2.74h-30.957zm33.332 0v2.74h13.871v-2.74h-13.871zm19.234 0l1.016 2.74h3.949l.953-2.74h-5.918zm11.187 0v2.74H181v-2.74h-14.024zm-85.824 5.326V76h19.465v-2.737H81.152zm22.071 0v2.733h21.152c3.753 0 6.586-2.733 6.586-2.733h-27.738zm33.332 0V76h13.871v-2.737h-13.871zm21.16 0l.976 2.73.168.003.993-2.733h-2.137zm9.261 0V76H181v-2.737h-14.024z"
        fill="#1F70C1"
      />
    </svg>
  )
}

export default SvgIbm
