const contacts = {
  email: 'info@ydev.academy',
  phoneNo: '+234 816 662 6000',
  blogLink: 'https://ydevacademy.medium.com/',
  enterpriseEmail: 'enterprise@ydev.academy',
  address: '17A, Jibowu Street, off Alakija Street, Jibowu, Yaba.',
  termAddress:
    '17A, Jibowu Street, off Alakija Street, Jibowu, Yaba, Lagos Nigeria.',
  socials: {
    facebook: 'https://web.facebook.com/ydevacademy?_rdc=1&_rdr',
    linkedIn: 'https://www.linkedin.com/company/ydevacademy/',
    instagram: 'https://www.instagram.com/ydevacademy/',
    twitter: 'http://www.twitter.com/ydevacademy',
  },
}

export default contacts
