import * as React from "react";

function SvgRocket2(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Rocket2_svg__clip0_2615:360)" fill="#000">
        <path d="M12.973 13.773l-4.418.34a3.942 3.942 0 00-3.005 1.769L.454 23.589a2.72 2.72 0 00-.219 2.61 2.72 2.72 0 002.08 1.595l4.045.626c.947-5.022 3.212-10.032 6.613-14.648zM22.58 44.64l.627 4.046a2.72 2.72 0 001.594 2.08 2.724 2.724 0 002.611-.22l7.707-5.096a3.943 3.943 0 001.768-3.004l.34-4.419c-4.615 3.401-9.625 5.667-14.647 6.613zM21.058 41.836c.14 0 .281-.011.422-.035a29.276 29.276 0 006.067-1.72L10.919 23.453A29.268 29.268 0 009.2 29.52c-.139.83.14 1.674.734 2.269l9.278 9.278c.494.493 1.16.77 1.847.77zM46.932 22.611c4.07-7.866 4.22-16.179 4.007-20.078A2.619 2.619 0 0048.466.06 41.114 41.114 0 0046.23 0c-4.359 0-11.257.66-17.843 4.067-5.233 2.707-12.096 8.667-16.145 16.5.048.038.095.078.139.122l17.93 17.93c.043.043.083.09.12.138 7.834-4.05 13.794-10.912 16.501-16.145zm-17.28-11.83c2.913-2.912 7.652-2.912 10.565 0a7.422 7.422 0 012.188 5.283 7.422 7.422 0 01-2.188 5.283 7.45 7.45 0 01-5.282 2.184 7.446 7.446 0 01-5.283-2.184 7.421 7.421 0 01-2.188-5.283c0-1.995.777-3.871 2.188-5.282z" />
        <path d="M31.766 19.234a4.488 4.488 0 006.34 0 4.453 4.453 0 001.312-3.17 4.453 4.453 0 00-1.313-3.17 4.469 4.469 0 00-3.17-1.31 4.468 4.468 0 00-3.169 1.31 4.453 4.453 0 00-1.313 3.17c0 1.198.466 2.323 1.313 3.17zM1.523 42.029c.383 0 .765-.146 1.057-.438l4.878-4.878A1.494 1.494 0 005.345 34.6L.467 39.478a1.494 1.494 0 001.056 2.55zM11.93 39.07a1.494 1.494 0 00-2.114 0L.438 48.45a1.494 1.494 0 102.113 2.112l9.378-9.378a1.494 1.494 0 000-2.114zM14.286 43.541L9.408 48.42a1.494 1.494 0 102.113 2.113l4.878-4.878a1.494 1.494 0 00-2.113-2.113z" />
      </g>
      <defs>
        <clipPath id="Rocket2_svg__clip0_2615:360">
          <path fill="#fff" d="M0 0h51v51H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRocket2;
