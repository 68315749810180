import * as React from "react";

function SvgTarget(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.963 1.927c-4.326 0-8.59 1.043-12.412 3.023L12.08.481a1.642 1.642 0 00-2.7.588L7.124 7.124 1.07 9.381a1.643 1.643 0 00-.588 2.7l4.47 4.47a27.002 27.002 0 00-3.024 12.413C1.927 43.87 14.056 56 28.964 56 43.87 56 56 43.871 56 28.964 56 14.056 43.871 1.927 28.963 1.927zm0 35.935c4.907 0 8.899-3.992 8.899-8.898 0-4.907-3.992-8.898-8.899-8.898-1.86 0-3.589.575-5.019 1.555l-4.13-4.13a14.611 14.611 0 019.15-3.21c8.095 0 14.681 6.587 14.681 14.683 0 8.095-6.586 14.681-14.682 14.681-8.095 0-14.681-6.586-14.681-14.681 0-3.457 1.202-6.639 3.208-9.15l4.131 4.13a8.846 8.846 0 00-1.556 5.02c0 4.906 3.992 8.898 8.898 8.898zm1.162-10.06l-3.794-3.794a5.578 5.578 0 012.633-.657 5.619 5.619 0 015.612 5.613 5.619 5.619 0 01-5.613 5.612 5.619 5.619 0 01-5.612-5.613c0-.95.238-1.846.657-2.632l3.794 3.794c.32.32.741.481 1.162.481a1.643 1.643 0 001.162-2.804zM11.567 4.612l5.527 5.529-.71 3.92-6.075-6.075 1.258-3.374zm-6.955 6.955l3.374-1.258 6.075 6.075-3.92.71-5.529-5.527zm24.352 41.148c-13.097 0-23.751-10.655-23.751-23.751 0-3.448.753-6.85 2.192-9.958l1.02 1.02a1.642 1.642 0 001.455.455l3.62-.656a17.857 17.857 0 00-2.504 9.139c0 9.907 8.06 17.967 17.968 17.967 9.907 0 17.967-8.06 17.967-17.967 0-9.907-8.06-17.968-17.967-17.968-3.335 0-6.46.915-9.14 2.504l.657-3.62a1.643 1.643 0 00-.455-1.454l-1.02-1.02a23.718 23.718 0 019.958-2.193c13.096 0 23.75 10.654 23.75 23.75 0 13.097-10.654 23.752-23.75 23.752z"
        fill="#83C6F2"
      />
    </svg>
  );
}

export default SvgTarget;
