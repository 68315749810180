import * as React from "react";

function SvgMentor(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Mentor_svg__clip0)">
        <path
          d="M15.228 18.546a2.038 2.038 0 100-4.075 2.038 2.038 0 000 4.075z"
          fill="#FFCEBF"
        />
        <path
          d="M17.45 22h-4.444v-1.233a2.222 2.222 0 114.444 0V22z"
          fill="#3C87D0"
        />
        <path
          d="M19.672 18.546a2.038 2.038 0 100-4.075 2.038 2.038 0 000 4.075z"
          fill="#FFCEBF"
        />
        <path
          d="M21.894 22H17.45v-1.233a2.222 2.222 0 114.444 0V22z"
          fill="#3C87D0"
        />
        <path
          d="M14.308 6.597h1.84c.12-.357.318-.682.58-.952.387-.396.617-.946.591-1.55a2.101 2.101 0 00-2.009-2 2.093 2.093 0 00-1.583 3.55c.263.27.461.595.581.952z"
          fill="#FDB97E"
        />
        <path
          d="M14.309 6.598h1.839v.753a.92.92 0 01-1.84 0v-.753z"
          fill="#537983"
        />
        <path
          d="M15.228 1.422a.329.329 0 01-.329-.329V.33a.329.329 0 11.658 0v.764a.329.329 0 01-.329.33zM13.434 1.93a.328.328 0 01-.233-.096l-.54-.54a.329.329 0 01.465-.465l.54.54a.329.329 0 01-.232.562zM17.022 1.93a.329.329 0 01-.233-.561l.541-.54a.329.329 0 01.465.465l-.54.54a.328.328 0 01-.233.097zM19.002 3.443h-.764a.329.329 0 110-.658h.764a.329.329 0 110 .658zM12.218 3.443h-.764a.329.329 0 110-.658h.764a.329.329 0 110 .658z"
          fill="#FEA95C"
        />
        <path
          d="M18.425 11.993h-.361V9.795a.614.614 0 00-.614-.613h-4.565a.614.614 0 000 1.227h3.952v1.584h-.361a.173.173 0 00-.145.27l.975 1.461c.069.103.22.103.288 0l.975-1.462a.173.173 0 00-.144-.269z"
          fill="#3C87D0"
        />
        <path
          d="M2.015 13.967a1.909 1.909 0 00-1.909 1.909v3.273h11.45v-3.274a1.909 1.909 0 00-1.91-1.908h-7.63z"
          fill="#C4E2FF"
        />
        <path
          d="M9.646 13.967h-1.49c1.053 0 1.908.854 1.908 1.909v3.273h1.491v-3.274a1.909 1.909 0 00-1.909-1.908z"
          fill="#B3DAFE"
        />
        <path
          d="M4.063 10.291v2.867l1.761 1.174 1.762-1.174V10.29H4.062z"
          fill="#FFBFB1"
        />
        <path
          d="M10.077 6.124l-.06.002a4.253 4.253 0 00-8.386 0l-.06-.002a1.444 1.444 0 10.06 2.887 4.253 4.253 0 008.387 0l.059.001a1.444 1.444 0 100-2.888z"
          fill="#FFCEBF"
        />
        <path
          d="M10.077 6.124l-.06.002a4.253 4.253 0 00-8.386 0l-.06-.002a1.444 1.444 0 10.06 2.887 4.253 4.253 0 008.387 0l.059.001a1.444 1.444 0 100-2.888z"
          fill="#FFCEBF"
        />
        <path
          d="M10.077 6.124l-.059.002a4.253 4.253 0 00-4.815-3.5 4.255 4.255 0 013.572 3.5l.06-.002a1.444 1.444 0 11-.06 2.887 4.255 4.255 0 01-3.572 3.5 4.253 4.253 0 004.815-3.5l.059.001a1.444 1.444 0 100-2.888z"
          fill="#FFBFB1"
        />
        <path
          d="M3.749 8.597a.329.329 0 01-.33-.329v-.783a.329.329 0 11.659 0v.783a.329.329 0 01-.33.329zM7.9 8.597a.329.329 0 01-.329-.329v-.783a.329.329 0 11.658 0v.783a.329.329 0 01-.329.329z"
          fill="#384949"
        />
        <path
          d="M5.825 10.83c-.68 0-1.232-.552-1.232-1.232a.329.329 0 11.657 0 .575.575 0 001.149 0 .329.329 0 11.658 0c0 .68-.553 1.232-1.232 1.232z"
          fill="#FFB09E"
        />
        <path
          d="M8.737 1.177A1.799 1.799 0 007.209.328h-3.46A3.191 3.191 0 00.56 3.52v2.607h6.04a2.4 2.4 0 001.608-.615c.298.375.758.615 1.274.615h1.61V3.565a2.388 2.388 0 00-2.354-2.388z"
          fill="#C4573A"
        />
        <path
          d="M5.824 14.332l-.903.943.327 1.283H6.4l.327-1.283-.903-.943z"
          fill="#FD544D"
        />
        <path
          d="M5.248 16.557l-.509 2.59h2.17l-.51-2.59H5.25z"
          fill="#FD544D"
        />
        <path
          d="M4.063 13.158l-1.49.81 2.101 1.566 1.15-1.201-1.761-1.175zM7.586 13.158l1.49.81-2.102 1.566-1.15-1.201 1.762-1.175z"
          fill="#B3DAFE"
        />
        <path
          d="M8.805 9.57H8.26a.36.36 0 010-.718h.545a.36.36 0 010 .718zM3.389 9.57h-.545a.36.36 0 010-.718h.545a.36.36 0 010 .718z"
          fill="#FFB09E"
        />
        <path
          d="M7.586 13.158l1.49.81-2.102 1.566-1.15-1.201 1.762-1.175z"
          fill="#B3DAFE"
        />
        <path
          d="M8.736 1.177A1.799 1.799 0 007.208.328H5.717c.645 0 1.21.34 1.528.85A2.388 2.388 0 019.6 3.564v2.561h1.491V3.565a2.388 2.388 0 00-2.354-2.388z"
          fill="#A74B30"
        />
        <path
          d="M7.587 13.158l-.67.447.668.362-1.231.918.62.649 2.102-1.567-1.49-.809z"
          fill="#8AC9FE"
        />
        <path
          d="M17.32 4.096a2.101 2.101 0 00-2.528-1.956 2.103 2.103 0 011.657 1.956 2.086 2.086 0 01-.59 1.55c-.263.27-.461.594-.581.951h.87c.12-.357.318-.682.58-.952.387-.397.617-.946.591-1.55z"
          fill="#FEA95C"
        />
        <path
          d="M15.279 6.598v.753a.92.92 0 01-.485.81.92.92 0 001.355-.81v-.753h-.87z"
          fill="#3E5959"
        />
        <path
          d="M15.229 14.47c-.217 0-.426.035-.622.098a2.038 2.038 0 010 3.881 2.038 2.038 0 10.621-3.978zM19.672 14.47c-.217 0-.425.035-.621.098a2.038 2.038 0 010 3.881 2.038 2.038 0 10.621-3.978z"
          fill="#FFBFB1"
        />
        <path
          d="M15.229 18.545a2.22 2.22 0 00-.622.089 2.222 2.222 0 011.601 2.133V22h1.243v-1.233a2.222 2.222 0 00-2.222-2.222zM19.673 18.545a2.22 2.22 0 00-.621.089 2.223 2.223 0 011.6 2.133V22h1.243v-1.233a2.222 2.222 0 00-2.222-2.222z"
          fill="#0078C8"
        />
      </g>
      <defs>
        <clipPath id="Mentor_svg__clip0">
          <path fill="#fff" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgMentor;
