import * as React from "react";

function SvgCusion(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={5.424} cy={7.478} r={5.34} fill="#5898C1" />
      <path
        d="M5.803 4.207v2.777c0 .291-.013.577-.04.859-.025.278-.059.58-.101.903h-.704c-.045-.324-.08-.625-.107-.903a10.712 10.712 0 01-.034-.86V4.208h.986zm-1.185 6.388a.7.7 0 01.049-.262.68.68 0 01.626-.417c.094 0 .181.018.262.053a.68.68 0 01.413.626.656.656 0 01-.2.48.573.573 0 01-.213.142.644.644 0 01-.262.053.656.656 0 01-.267-.053.63.63 0 01-.214-.141.695.695 0 01-.145-.214.713.713 0 01-.049-.267z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCusion;
